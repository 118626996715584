import { Headline } from '../atoms/Headline/Headline';
import { ListItem, ListItems } from '../atoms/ListItems/ListItems';

export function Stallungen() {
    return (
        <div>
            <Headline>Stallungen</Headline>
            <p>
                <img src="./static/stallungen/haus.jpg" />
            </p>
            <p>Eckdaten:</p>
            <ListItems>
                <ListItem>Die Stallungen werden von 2 Milchziegen, 12 Zwerghüner und natürlich 8 Alpakas bewohnt.</ListItem>
                <ListItem> ca. 9900m2 Weidefläche</ListItem>
            </ListItems>
        </div>
    );
}
