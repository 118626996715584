import { PropsWithChildren, useState } from 'react';
import ReactBnbGallery from 'react-bnb-gallery';

const phrases = {
    noPhotosProvided: 'Keine Bilder verfügbar',
    showPhotoList: 'Zeige Bilderliste',
    hidePhotoList: 'Verstecke Bilderliste',
};

export function MyLightbox({ images, children }: PropsWithChildren<{ name: string; images: string[] }>) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <a onClick={() => setIsOpen(true)}>{children}</a>
            <ReactBnbGallery phrases={phrases} show={isOpen} photos={images} onClose={() => setIsOpen(false)} />
        </>
    );
}
// import { PropsWithChildren, useState } from 'react';
// import Lightbox from 'react-image-lightbox';

// export function MyLightbox({ images, children }: PropsWithChildren<{ name: string; images: string[] }>) {
//     const [photoIndex, setIndex] = useState(0);
//     const [isOpen, setOpen] = useState(false);
//     return (
//         <a onClick={() => setOpen(true)}>
//             {children}

//             {isOpen && (
//                 <Lightbox
//                     mainSrc={images[photoIndex]}
//                     nextSrc={images[(photoIndex + 1) % images.length]}
//                     prevSrc={images[(photoIndex + images.length - 1) % images.length]}
//                     onCloseRequest={() => setOpen(false)}
//                     onMovePrevRequest={() => setIndex((photoIndex + images.length - 1) % images.length)}
//                     onMoveNextRequest={() => setIndex((photoIndex + 1) % images.length)}
//                 />
//             )}
//         </a>
//     );
// }
