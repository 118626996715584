import { Headline } from '../atoms/Headline/Headline';

export function Alpaka() {
    return (
        <div>
            <Headline>Alpaka</Headline>
            <p>
                Das Alpaka (Lama pacos) ist eine Art der Lamas. Es ist in den suedamerikanischen Anden verbreitet und eine wahrscheinlich
                vom Guanako abstammende Haustierform.
            </p>
            <p>
                Alpakas sind etwas kleiner als Lamas, mit einem Gewicht von etwa 65 kg aber vor allem viel leichter. Die Farbe ist meistens
                einfarbig braun, schwarz oder blaugrau, in seltenen Faellen auch gescheckt. Auffaellig sind die langen Haare, die eine
                Laenge von 50 cm erreichen koennen.
            </p>
            <p>
                Die Domestikation erfolgte schon um 3000 v. Chr. Es ist noch immer nicht ganz geklaert, welches Tier der Vorfahr des Alpakas
                ist. Die lange Zeit gaengige Lehrmeinung sah das Guanako als gemeinsamen Vorfahren des Lamas und des Alpakas. Schon frueh
                gab es aber Theorien, wonach das Alpaka vom Vikunja abstamme - eine Auffassung, die durch neue DNA-Untersuchungen teilweise
                bestaetigt zu werden scheint. Dass keine hundertprozentige Gewissheit erlangt wird, liegt vor allem daran, dass Vikunjas,
                Guanakos, Lamas und Alpakas uneingeschraenkt untereinander fruchtbar sind, so dass sich die Linien immer wieder vermischt
                haben.
            </p>
            <p>
                Waehrend das Lama den suedamerikanischen Zivilisationen vor allem als Lasttier diente, wurde das Alpaka wegen seiner Wolle
                gezuechtet. Bei den Inka galt ein Alpaka-Mantel als Zeichen des Wohlstands; die Herrscher der Inka hielten sich grosse
                Alpaka-Herden, um ihre Macht zu demonstrieren. Dies aenderte sich mit der Eroberung Perus durch die Spanier. Die Eroberer
                brachten Schafe mit und zeigten kein Interesse daran, die einheimischen Nutztiere zu erforschen; so wurde das Alpaka zu
                einem Tier der armen, indianischen Bevoelkerung, das zwischenzeitlich fast ausgestorben waere.
            </p>
            <p>
                Erst mit der Unabhaengigkeit der Staaten Suedamerikas erkannte man wieder den Wert des Alpakas. Es wurde wieder gezuechtet,
                und seine Wolle in alle Welt exportiert. Heute gibt es wieder 3 Millionen Alpakas.
            </p>
            <p>
                Auch in Deutschland nimmt die Bedeutung der Alpakas immer mehr zu, ob als reines Hobbytier oder als Zuchtier. Zuchttiere
                werden meistens importiert, da die Alpakas in Deutschland noch nicht die notwendige Qualitaet der Faser aufweisen. In
                Deutschland gibt es derzeit etwa 2000 Alpakas (Quelle:
                <a target="_blank" href="http://www.wikipedia.org">
                    www.wikipedia.org
                </a>
                ).
            </p>
        </div>
    );
}
