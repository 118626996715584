import { Headline } from '../atoms/Headline/Headline';
import useMail from '../hook/mailLink/useMail';

export function Kontakt() {
    const [val] = useMail();
    return (
        <div>
            <Headline>Kontakt</Headline>
            <p>
                <b>Anschrift:</b>
                <br />
                Manfred Zeh
                <br />
                Wiesenweg 25
                <br />
                95233 Helmbrechts
                <br />
                <br />
                <b>Telefon: </b>
                <br />
                09252 / 5276
                <br />
                <br />
                <br />
                <b>Fax:</b>
                <br />
                09252 / 357 66 33
                <br />
                <br />
                <b>Email:</b>
                <br />
                <a href={'mailto:' + val}>{val}</a>
            </p>
        </div>
    );
}
