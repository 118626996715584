import classes from './Tiere.module.scss';
import { Headline, Headline2 } from '../atoms/Headline/Headline';

const data = [
    {
        name: 'Caecillia',
        img: '/static/tiere/Caecilia.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>MMR Caecilia</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Stute</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>11.05.2002</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Kanada</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>Medium Fawn / 204 MF</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Ilena',
        img: '/static/tiere/Ilena.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td> <td>Ilena</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td> <td>Stute</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td> <td>23.09.2003</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td> <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td> <td>Beige / 201</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Samson',
        img: '/static/tiere/Samson.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Samson</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Hengst</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>03.05.2005</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>Beige / 201</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Constanze',
        img: '/static/tiere/Constanze.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Constanze</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Stute</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>29.07.2009</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>204 hell Beige</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Santos',
        img: '/static/tiere/Santos.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Santos</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Hengst</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>05.05.2006</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>204 hell Beige</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Cornelia',
        img: '/static/tiere/Cornelia.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Cornelia</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Stute</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>10.07.2010</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>Braun</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Ilona',
        img: '/static/tiere/Ilona.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Ilona</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Stute</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>30.07.2010</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>Weiss</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Samuel',
        img: '/static/tiere/Samuel.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Samuel</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Hengst</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>01.08.2011</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>Weiss</td>
                </tr>
            </table>
        ),
    },
    {
        name: 'Santro',
        img: '/static/tiere/Santro.jpg',
        jsx: (
            <table>
                <tr>
                    <td>Name:</td>
                    <td>Santro</td>
                </tr>
                <tr>
                    <td>Rasse:</td>
                    <td>Huacaya</td>
                </tr>
                <tr>
                    <td>Geschlecht:</td>
                    <td>Hengst</td>
                </tr>
                <tr>
                    <td>Geburtstag:</td>
                    <td>05.07.2011</td>
                </tr>
                <tr>
                    <td>Geburtsland:</td>
                    <td>Deutschland</td>
                </tr>
                <tr>
                    <td>Farbe:</td>
                    <td>Braun</td>
                </tr>
            </table>
        ),
    },
];

export function Tiere() {
    return (
        <div>
            <Headline>Tiere</Headline>
            {/* <div className={classes.overview}>
                {data.map((item) => (
                    <div key={'th_' + item.name} className={classes.item}>
                        <a href={'#' + item.name}>
                            <img src={item.img.replace('.jpg', 'Tn.jpg')}></img>
                            <div>{item.name}</div>
                        </a>
                    </div>
                ))}
            </div> */}
            {data.map((item) => (
                <div key={'entry_' + item.name} id={item.name} className={classes.entry}>
                    <Headline2>{item.name}</Headline2>
                    <div className={classes.entryline}>
                        <img src={item.img}></img>

                        {item.jsx}
                    </div>
                </div>
            ))}
        </div>
    );
}
