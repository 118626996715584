const x: number[] = [
    109,
    97,
    110,
    102,
    114,
    101,
    100,
    64,
    102,
    114,
    97,
    110,
    107,
    101,
    110,
    119,
    97,
    108,
    100,
    45,
    97,
    108,
    112,
    97,
    107,
    97,
    46,
    100,
    101,
];
export const getAddr = () => String.fromCharCode(...x);
