import classes from './Layout.module.scss';
import { PropsWithChildren } from 'react';
import { Header } from '../Header/Header';
import { SideBar } from '../SideBar/SideBar';

export function Layout(props: PropsWithChildren<{}>) {
    return (
        <div className={classes.container}>
            <SideBar></SideBar>
            <div>
                <Header></Header>
                <div className={classes.content}>{props.children}</div>
            </div>
        </div>
    );
}
