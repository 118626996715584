import { HashRouter, Route, Switch } from 'react-router-dom';
import { Alpaka } from './Alpaka/Alpaka';
import { Galerie } from './Galerie/Galerie';
import { Home } from './Home/Home';
import { Impressum } from './Impressum/Impressum';
import { Kontakt } from './Kontakt/Kontakt';
import { Layout } from './Layout/Layout';
import { Stallungen } from './Stallungen/Stallungen';
import { Tiere } from './Tiere/Tiere';

function App() {
    return (
        <HashRouter>
            <Layout>
                <Switch>
                    <Route path={'/alpaka'} component={Alpaka}/>
                    <Route path="/tiere" component={Tiere}/>
                    <Route path="/stallungen" component={Stallungen}/>
                    <Route path="/galerie" component={Galerie}/>
                    <Route path="/impressum" component={Impressum}/>
                    <Route path="/kontakt" component={Kontakt}/>
                    <Route component={Home}/>
                </Switch>
            </Layout>
        </HashRouter>
    );
}

export default App;
