import classes from './Galerie.module.scss';
import { Headline } from '../atoms/Headline/Headline';
import data from './images.json';
import { MyLightbox } from './MyLightbox';

function fixPath(path: string) {
    return './static/images/' + path;
}

export function Galerie() {
    const obj: { [key: string]: string[] } = data;
    return (
        <div className={classes.container}>
            <Headline>Galerie</Headline>
            <div className={classes.list}>
                {Object.keys(obj).map((name: string) => (
                    <div key={'th_' + name} className={classes.item}>
                        <MyLightbox name={name} images={obj[name].map(fixPath)}>
                            <img src={fixPath(name)}></img>
                            <div>{name.replace('.jpg', '').replace('_', ' ')}</div>
                        </MyLightbox>
                    </div>
                ))}
            </div>
        </div>
    );
}
