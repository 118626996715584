import { getAddr } from './getAddress';
import { useState, useEffect } from 'react';

export default function useMail() {
    const [mail, setMail] = useState('[... Spam Schutz ...]');
    useEffect(() => {
        const id = setTimeout(() => {
            setMail(getAddr());
        }, 500);
        return () => clearTimeout(id);
    }, []);
    return [mail];
}
