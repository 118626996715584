import classes from './SideBar.module.scss';

import { NavLink } from 'react-router-dom';
import { ListItem, ListItems } from '../atoms/ListItems/ListItems';

export function SideBar() {
    return (
        <div className={classes.container}>
            <div className={classes.greenbar}></div>
            <div className={classes.alpaka}></div>
            <div className={classes.content}>
                <div className={classes.menu}>
                    <ListItems>
                        <ListItem>
                            <NavLink to="/">Home</NavLink>{' '}
                        </ListItem>
                        <ListItem>
                            <NavLink to="/alpaka">Alpaka</NavLink>{' '}
                        </ListItem>
                        <ListItem>
                            <NavLink to="/tiere">Tiere</NavLink>{' '}
                        </ListItem>
                        <ListItem>
                            <NavLink to="/stallungen">Stallungen</NavLink>{' '}
                        </ListItem>
                        <ListItem>
                            <NavLink to="/galerie">Galerie</NavLink>{' '}
                        </ListItem>
                        <ListItem>
                            <NavLink to="/impressum">Impressum</NavLink>{' '}
                        </ListItem>
                        <ListItem>
                            <NavLink to="/kontakt">Kontakt</NavLink>{' '}
                        </ListItem>
                    </ListItems>
                </div>
                <div className={classes.guesthouse}></div>
                <div className={classes.azvd}></div>
            </div>
        </div>
    );
}
